import React, { useEffect, useRef } from "react";

const FadeIn = (props) => {
  const containerRef = useRef(null);
  useEffect(() => {
    containerRef.current.classList.add("fade-in");
  }, []);

  return (
    <div className={props.class} ref={containerRef}>
      {props.children}
    </div>
  );
};

export default FadeIn;
