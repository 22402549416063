import "./App.scss";

import { BrowserRouter, Route, Switch } from "react-router-dom";
import React, { useEffect, useState } from "react";

import Backdrop from "../components/backdrop/backdrop";
import Hero from "../components/hero/hero";
import Landing from "./landing/landing";
import Landscape from "./photography/landscape/landscape";
import Navigation from "../components/navigation/navigation";
import NavigationTop from "../components/navigation/navigation-top";
import ScrollToTop from "../hoc/scroll-to-top/scroll-to-top";
import Street from "./photography/street/street";
import Web from "./development/web";
import utils from "../lib/utils";

const App = () => {
  const isMobile = utils.useWindowSize().width <= 640;
  let [isNavigationOpen, setNavigationOpen] = useState(false);

  const showMobileNavigationHandler = () => {
    document.body.classList.add("no-scroll");
    setNavigationOpen(true);
  };

  const closeMobileNavigationHandler = () => {
    document.body.classList.remove("no-scroll");
    setNavigationOpen(false);
  };

  return (
    <BrowserRouter>
      <ScrollToTop />
      <main>
        {isMobile ? (
          <NavigationTop showNavigation={showMobileNavigationHandler} />
        ) : null}
        {isMobile ? (
          <Backdrop
            show={isNavigationOpen}
            clicked={closeMobileNavigationHandler}
          />
        ) : null}
        <Navigation
          show={isNavigationOpen}
          clicked={closeMobileNavigationHandler}
        />
        <div className='content'>
          <Switch>
            <Route path='/photography/landscape' exact component={Landscape} />
            <Route path='/photography/street' exact component={Street} />
            {/* <Route path='/development/web' exact component={Web} /> */}
            <Route path='/' component={Landscape} />
          </Switch>
        </div>
      </main>
    </BrowserRouter>
  );
};

export default App;
