const data = [
  {
    id: 1,
    url: require("../../../assets/images/street/20181003-IMG_2207-Edit.jpg"),
    location: "Italy",
    date: "October 2018",
  },
  {
    id: 2,
    url: require("../../../assets/images/street/20201006-DSCF1872-Edit.jpg"),
    location: "Italy",
    date: "October 2020",
  },
  {
    id: 3,
    url: require("../../../assets/images/street/20181002-IMG_1759-2-Edit-2-Edit.jpg"),
    location: "Italy",
    date: "October 2018",
  },
  {
    id: 4,
    url: require("../../../assets/images/street/20200704-DSCF0411-Edit-Edit.jpg"),
    location: "Belgium",
    date: "July 2018",
  },
  {
    id: 5,
    url: require("../../../assets/images/street/20201005-DSCF1580.jpg"),
    location: "Italy",
    date: "October 2020",
  },
  {
    id: 6,
    url: require("../../../assets/images/street/20180310-IMG_0668-Edit.jpg"),
    location: "Belgium",
    date: "March 2018",
  },
  {
    id: 7,
    url: require("../../../assets/images/street/20181001-IMG_1450-Edit-Edit-Edit.jpg"),
    location: "Italy",
    date: "October 2018",
  },
  {
    id: 8,
    url: require("../../../assets/images/street/20200809-DSCF0886.jpg"),
    location: "Belgium",
    date: "August 2020",
  },
  {
    id: 9,
    url: require("../../../assets/images/street/20181013-IMG_2803-Edit-2.jpg"),
    location: "Germany",
    date: "October 2018",
  },
  {
    id: 10,
    url: require("../../../assets/images/street/20201006-DSCF1773.jpg"),
    location: "Italy",
    date: "October 2020",
  },
];

export default data;
