const data = [
  {
    id: 1,
    url: require("../../../assets/images/landscape/20201003-DSCF1293.jpg"),
    location: "Italy",
    date: "October 2020",
  },

  {
    id: 2,
    url: require("../../../assets/images/landscape/20190809-IMG_4238.jpg"),
    location: "Iceland",
    date: "August 2019",
  },
  {
    id: 3,
    url: require("../../../assets/images/landscape/20190810-IMG_4404-Edit-2.jpg"),
    location: "Iceland",
    date: "August 2019",
  },
  {
    id: 4,
    url: require("../../../assets/images/landscape/20191020-IMG_6528-Edit-Edit.jpg"),
    location: "Japan",
    date: "October 2019",
  },
  {
    id: 5,
    url: require("../../../assets/images/landscape/20191114-IMG_8572.jpg"),
    location: "Peru",
    date: "November 2019",
  },
  {
    id: 6,
    url: require("../../../assets/images/landscape/20191114-IMG_8573.jpg"),
    location: "Italy",
    date: "October 2020",
  },
  {
    id: 7,
    url: require("../../../assets/images/landscape/20091118-20191118_054723-Edit-2.jpg"),
    location: "Peru",
    date: "November 2019",
  },
  {
    id: 8,
    url: require("../../../assets/images/landscape/20201004-DSCF1319.jpg"),
    location: "Italy",
    date: "October 2020",
  },
  {
    id: 9,
    url: require("../../../assets/images/landscape/20201004-DSCF1345.jpg"),
    location: "Italy",
    date: "October 2020",
  },
  {
    id: 10,
    url: require("../../../assets/images/landscape/20201004-DSCF1372.jpg"),
    location: "Italy",
    date: "October 2020",
  },
  {
    id: 11,
    url: require("../../../assets/images/landscape/A.jpg"),
    location: "Iceland",
    date: "August 2019",
  },
  {
    id: 12,
    url: require("../../../assets/images/landscape/L.jpg"),
    location: "Iceland",
    date: "August 2019",
  },
  {
    id: 13,
    url: require("../../../assets/images/landscape/N.jpg"),
    location: "Japan",
    date: "October 2019",
  },
];

export default data;
