import { Link, NavLink } from "react-router-dom";
import React, { useState } from "react";

import style from "./navigation.module.scss";

const Navigation = (props) => {
  return (
    <nav
      className={`${style["navigation-left"]} ${props.show ? style["open"] : ""} 
        `}>
      <div className={style["logo"]}>
        <Link to='/'>
          {/* <span>nielsdrx.com</span> */}
          <img src={require("../../assets/logo.svg")} alt='logo' />
        </Link>
      </div>
      <div className={style["nav-container"]}>
        <ul>
          <li className={style["nav-item"]}>
            <span className={style["nav-title"]}>Photography</span>
            <ul className={style["nav-sub-list"]}>
              <li>
                <NavLink
                  to='/photography/landscape'
                  className={style["nav-sub-item"]}
                  activeClassName={style["active"]}
                  onClick={props.clicked}>
                  <span className={style["line-wrapper"]}>
                    <span className={style["line"]} />
                  </span>
                  <span>Landscape</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to='/photography/street'
                  className={style["nav-sub-item"]}
                  activeClassName={style["active"]}
                  onClick={props.clicked}>
                  <span className={style["line-wrapper"]}>
                    <span className={style["line"]} />
                  </span>
                  <span>Street</span>
                </NavLink>
              </li>
              {/* <li>
                  <Link
                    to='/photography/people'
                    className={style["nav-sub-item"]}>
                    <span className={style["line-wrapper"]}>
                      <span className={style["line"]} />
                    </span>
                    <span>People</span>
                  </Link>
                </li> */}
            </ul>
          </li>
          {/* <li className={style["nav-item"]}>
            <span className={style["nav-title"]}>Development</span>
            <ul className={style["nav-sub-list"]}>
              <li>
                <NavLink
                  to='/development/web'
                  className={style["nav-sub-item"]}
                  activeClassName={style["active"]}>
                  <span className={style["line-wrapper"]}>
                    <span className={style["line"]} />
                  </span>
                  <span>Web</span>
                </NavLink>
              </li>
            </ul>
          </li> */}
          {/* <li className={style["nav-item"]}>
              <span className={style["nav-title"]}>Travel</span>
              <ul className={style["nav-sub-list"]}>
                <li>
                  <Link to='/italy' className={style["nav-sub-item"]}>
                    <span className={style["line-wrapper"]}>
                      <span className={style["line"]} />
                    </span>
                    <span>Italy</span>
                  </Link>
                </li>
                <li>
                  <Link to="/peru" className={style["nav-sub-item"]}>
                    <span className={style["line"]} />
                    <span>Peru</span>
                  </Link>
                </li>
                <li>
                  <Link to="/japan" className={style["nav-sub-item"]}>
                    <span className={style["line"]} />
                    <span>Japan</span>
                  </Link>
                </li>
                <li>
                  <Link to="/iceland" className={style["nav-sub-item"]}>
                    <span className={style["line"]} />
                    <span>Iceland</span>
                  </Link>
                </li>
              </ul>
            </li> */}

          {/* <li className={style["nav-item"]}>
              <a href='contact' className={style["nav-title"]}>
                Contact
              </a>
            </li> */}
        </ul>
      </div>
      <div className={style["social"]}>
        <a href='https://www.instagram.com/nielsdrx/' rel='noopener noreferrer' target='_blank'>
          <svg
            height='512pt'
            viewBox='0 0 512 512'
            width='512pt'
            xmlns='http://www.w3.org/2000/svg'>
            <path d='m370.59375 169.304688c-2.355469-6.382813-6.113281-12.160157-10.996094-16.902344-4.742187-4.882813-10.515625-8.640625-16.902344-10.996094-5.179687-2.011719-12.960937-4.40625-27.292968-5.058594-15.503906-.707031-20.152344-.859375-59.402344-.859375-39.253906 0-43.902344.148438-59.402344.855469-14.332031.65625-22.117187 3.050781-27.292968 5.0625-6.386719 2.355469-12.164063 6.113281-16.902344 10.996094-4.882813 4.742187-8.640625 10.515625-11 16.902344-2.011719 5.179687-4.40625 12.964843-5.058594 27.296874-.707031 15.5-.859375 20.148438-.859375 59.402344 0 39.25.152344 43.898438.859375 59.402344.652344 14.332031 3.046875 22.113281 5.058594 27.292969 2.359375 6.386719 6.113281 12.160156 10.996094 16.902343 4.742187 4.882813 10.515624 8.640626 16.902343 10.996094 5.179688 2.015625 12.964844 4.410156 27.296875 5.0625 15.5.707032 20.144532.855469 59.398438.855469 39.257812 0 43.90625-.148437 59.402344-.855469 14.332031-.652344 22.117187-3.046875 27.296874-5.0625 12.820313-4.945312 22.953126-15.078125 27.898438-27.898437 2.011719-5.179688 4.40625-12.960938 5.0625-27.292969.707031-15.503906.855469-20.152344.855469-59.402344 0-39.253906-.148438-43.902344-.855469-59.402344-.652344-14.332031-3.046875-22.117187-5.0625-27.296874zm-114.59375 162.179687c-41.691406 0-75.488281-33.792969-75.488281-75.484375s33.796875-75.484375 75.488281-75.484375c41.6875 0 75.484375 33.792969 75.484375 75.484375s-33.796875 75.484375-75.484375 75.484375zm78.46875-136.3125c-9.742188 0-17.640625-7.898437-17.640625-17.640625s7.898437-17.640625 17.640625-17.640625 17.640625 7.898437 17.640625 17.640625c-.003906 9.742188-7.898437 17.640625-17.640625 17.640625zm0 0' />
            <path d='m305 256c0 27.0625-21.9375 49-49 49s-49-21.9375-49-49 21.9375-49 49-49 49 21.9375 49 49zm0 0' />
            <path d='m475.074219 0h-438.148438c-20.394531 0-36.925781 16.53125-36.925781 36.925781v438.148438c0 20.394531 16.53125 36.925781 36.925781 36.925781h438.148438c20.394531 0 36.925781-16.53125 36.925781-36.925781v-438.148438c0-20.394531-16.53125-36.925781-36.925781-36.925781zm-72.960938 316.605469c-.710937 15.648437-3.199219 26.332031-6.832031 35.683593-7.636719 19.746094-23.246094 35.355469-42.992188 42.992188-9.347656 3.632812-20.035156 6.117188-35.679687 6.832031-15.675781.714844-20.683594.886719-60.605469.886719-39.925781 0-44.929687-.171875-60.609375-.886719-15.644531-.714843-26.332031-3.199219-35.679687-6.832031-9.8125-3.691406-18.695313-9.476562-26.039063-16.957031-7.476562-7.339844-13.261719-16.226563-16.953125-26.035157-3.632812-9.347656-6.121094-20.035156-6.832031-35.679687-.722656-15.679687-.890625-20.6875-.890625-60.609375s.167969-44.929688.886719-60.605469c.710937-15.648437 3.195312-26.332031 6.828125-35.683593 3.691406-9.808594 9.480468-18.695313 16.960937-26.035157 7.339844-7.480469 16.226563-13.265625 26.035157-16.957031 9.351562-3.632812 20.035156-6.117188 35.683593-6.832031 15.675781-.714844 20.683594-.886719 60.605469-.886719s44.929688.171875 60.605469.890625c15.648437.710937 26.332031 3.195313 35.683593 6.824219 9.808594 3.691406 18.695313 9.480468 26.039063 16.960937 7.476563 7.34375 13.265625 16.226563 16.953125 26.035157 3.636719 9.351562 6.121094 20.035156 6.835938 35.683593.714843 15.675781.882812 20.683594.882812 60.605469s-.167969 44.929688-.886719 60.605469zm0 0' />
          </svg>
        </a>

        <a
          href='https://www.linkedin.com/in/niels-dierickx-b7735a48/'
          rel='noopener noreferrer'
          target='_blank'>
          <svg
            height='512pt'
            viewBox='0 0 512 512'
            width='512pt'
            xmlns='http://www.w3.org/2000/svg'>
            <path d='m475.074219 0h-438.148438c-20.394531 0-36.925781 16.53125-36.925781 36.925781v438.148438c0 20.394531 16.53125 36.925781 36.925781 36.925781h438.148438c20.394531 0 36.925781-16.53125 36.925781-36.925781v-438.148438c0-20.394531-16.53125-36.925781-36.925781-36.925781zm-293.464844 387h-62.347656v-187.574219h62.347656zm-31.171875-213.1875h-.40625c-20.921875 0-34.453125-14.402344-34.453125-32.402344 0-18.40625 13.945313-32.410156 35.273437-32.410156 21.328126 0 34.453126 14.003906 34.859376 32.410156 0 18-13.53125 32.402344-35.273438 32.402344zm255.984375 213.1875h-62.339844v-100.347656c0-25.21875-9.027343-42.417969-31.585937-42.417969-17.222656 0-27.480469 11.601563-31.988282 22.800781-1.648437 4.007813-2.050781 9.609375-2.050781 15.214844v104.75h-62.34375s.816407-169.976562 0-187.574219h62.34375v26.558594c8.285157-12.78125 23.109375-30.960937 56.1875-30.960937 41.019531 0 71.777344 26.808593 71.777344 84.421874zm0 0' />
          </svg>
        </a>
      </div>
    </nav>
  );
};

export default Navigation;
