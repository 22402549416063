import React, { useState } from "react";

import FadeIn from "../../../hoc/fade-in/fade-in";
import Grid from "../../../components/grid/grid";
import PhotoDetail from "../../../components/photo-detail/photo-detail";
import data from "./street.data";

const Street = () => {
  const [showPhotoDetail, setShowPhotoDetail] = useState(false);
  const [photoDetailId, setPhotoDetailId] = useState(null);

  const clickHandler = (id) => {
    setShowPhotoDetail(true);
    setPhotoDetailId(id);
  };

  const closeHandler = () => {
    setShowPhotoDetail(false);
    setPhotoDetailId(null);
  };

  const switchHandler = (id) => {
    setPhotoDetailId(id);
  };

  return (
    <div>
      {!showPhotoDetail && (
        <FadeIn>
          <div className='content-title'>
            <span className='line' />
            <span>Street</span>
          </div>
          <Grid data={data} clicked={clickHandler} />
        </FadeIn>
      )}
      {showPhotoDetail && (
        <PhotoDetail
          data={data}
          selected={photoDetailId}
          closed={closeHandler}
          switched={switchHandler}
        />
      )}
    </div>
  );
};

export default Street;
