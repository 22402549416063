import React, { useEffect } from "react";

import style from "./photo-detail.module.scss";

const PhotoDetail = (props) => {
  const selectedItem = props.data.find((item) => item.id === props.selected);
  const selectedIndex = selectedItem.id;

  const checkValidIndex = (index) => {
    if (index <= 0) return props.data.length;
    if (index > props.data.length) return 1;
    return index;
  };

  const handleWheel = (event) => {
    if (event.deltaY > 0) {
      setTimeout(() => {
        props.switched(checkValidIndex(selectedIndex + 1));
      }, 100);
    } else {
      setTimeout(() => {
        props.switched(checkValidIndex(selectedIndex - 1));
      }, 100);
    }
  };

  return (
    <div className={style["photo-detail-container"]} onWheel={handleWheel}>
      <div className={style["photo-detail-title-container"]}>
        <div className={style["photo-detail-title"]}>
          <span className={style["line"]} />
          <span>{selectedItem.location}</span>
        </div>
      </div>
      <div className={style["photo-detail-image-wrapper"]}>
        <img
          src={selectedItem.url}
          className='main-image'
          alt={selectedItem.location}
        />
      </div>

      <div
        className={style["photo-detail-prev"]}
        onClick={() => props.switched(checkValidIndex(selectedIndex - 1))}>
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 477.2 477.2'>
          <path d='M145.2 238.6l215.5-215.5c5.3-5.3 5.3-13.8 0-19.1s-13.8-5.3-19.1 0l-225.1 225.1c-5.3 5.3-5.3 13.8 0 19.1l225.1 225c2.6 2.6 6.1 4 9.5 4s6.9-1.3 9.5-4c5.3-5.3 5.3-13.8 0-19.1L145.2 238.6z' />
        </svg>
      </div>
      <div
        className={style["photo-detail-next"]}
        onClick={() => props.switched(checkValidIndex(selectedIndex + 1))}>
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 477.2 477.2'>
          <path d='M360.7 229.1l-225.1-225.1c-5.3-5.3-13.8-5.3-19.1 0s-5.3 13.8 0 19.1l215.5 215.5 -215.5 215.5c-5.3 5.3-5.3 13.8 0 19.1 2.6 2.6 6.1 4 9.5 4 3.4 0 6.9-1.3 9.5-4l225.1-225.1C365.9 242.9 365.9 234.3 360.7 229.1z' />
        </svg>
      </div>
      <div
        className={style["photo-detail-close"]}
        onClick={() => props.closed()}>
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
          <path d='M284.3 256L506.1 34.1c7.8-7.8 7.8-20.5 0-28.3 -7.8-7.8-20.5-7.8-28.3 0L256 227.7 34.1 5.9c-7.8-7.8-20.5-7.8-28.3 0 -7.8 7.8-7.8 20.5 0 28.3l221.9 221.9L5.9 477.9c-7.8 7.8-7.8 20.5 0 28.3 3.9 3.9 9 5.9 14.1 5.9 5.1 0 10.2-2 14.1-5.9L256 284.3l221.9 221.9c3.9 3.9 9 5.9 14.1 5.9s10.2-2 14.1-5.9c7.8-7.8 7.8-20.5 0-28.3L284.3 256z' />
        </svg>
      </div>
    </div>
  );
};

export default PhotoDetail;
