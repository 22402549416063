import "./grid.scss";

import React from "react";

const Grid = (props) => {
  return (
    <div className='gallery'>
      {props.data.map((item) => (
        <div className='image-container' key={item.id}>
          <div className='image-wrapper' onClick={() => props.clicked(item.id)}>
            <img alt={item.id} src={item.url} className='image' />
          </div>
          <img
            src={require("../../assets/icon-plus.png")}
            className='plus-image _1'
            alt='first'
          />
          <img
            src={require("../../assets/icon-plus.png")}
            className='plus-image _2'
            alt='first'
          />
          <img
            src={require("../../assets/icon-plus.png")}
            className='plus-image _3'
            alt='first'
          />
          <img
            src={require("../../assets/icon-plus.png")}
            className='plus-image _4'
            alt='first'
          />
          <div className='overlay-line-vertical'></div>
          <div className='overlay-line-horizontal'></div>
          <div className='overlay-text-left-wrapper'>
            <div className='overlay-text-left'>{item.date}</div>
          </div>
          <div className='overlay-text-bottom'>{item.location}</div>
        </div>
      ))}
    </div>
  );
};

export default Grid;
