import React from "react";
import style from "./backdrop.module.scss";

const Backdrop = (props) => {
  return props.show ? (
    <div className={style["navigation-backdrop"]} onClick={props.clicked}></div>
  ) : null;
};

export default Backdrop;
