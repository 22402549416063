import React from "react";
import style from "./navigation-top.module.scss";

const NavigationTop = (props) => {
  return (
    <div>
      <nav className={style["navigation-top"]}>
        <div>nielsdrx.com</div>
        <div className={style["hamburger-menu"]} onClick={props.showNavigation}>
          <svg viewBox='0 0 100 80' width='25' height='25'>
            <rect y='10' width='100' height='5'></rect>
            <rect y='40' x='30' width='80' height='5'></rect>
            <rect y='70' width='100' height='5'></rect>
          </svg>
        </div>
      </nav>
    </div>
  );
};

export default NavigationTop;
